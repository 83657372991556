/* src/index.css */

:root {
  --pad: max(20px, 4vmin);
  --color: #68cc59;
  --text-color: #fff;
}

h1 {
  margin-top: 10px;
  margin-bottom: 0;
}

ul {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
}

body {
  color: #fff;
  font-family: "Neue Montreal" !important;
  font-weight: 400;
  overflow: hidden;
  width: 100dvw;
  height: 10dvh;
  background-color: #0c0c0c;
  font-size: 14px;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

.App {
  width: 100vw;
  z-index: 0;
  height: 100vh;
}

.dark .corner1 {
  width: 0;
  height: 0;
  border-top: 250px solid var(--color);
  border-right: 250px solid transparent;
  z-index: 1;
}

.dark .corner2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 250px solid var(--color);
  border-left: 250px solid transparent;
  z-index: 1;
}

.dark .main {
  width: calc(100vw - 2 * var(--pad)) !important;
  height: calc(100vh - 2 * var(--pad)) !important;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  position: absolute;
  top: var(--pad);
  left: var(--pad);
  z-index: 2;
  border: 1px solid transparent;
  border: 1px solid white;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .header {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.dark .header h1 {
  font-size: 55px;
  font-weight: 400;
}

.dark .header h1 em {
  font-style: normal;
  font-weight: 700;
}

.dark .header p {
  font-size: 14px;
  font-weight: 400;
  margin-top: -5px;
}

.new-nav button {
  background: transparent;
  border: none;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .nav button:hover {
  opacity: 0.4;
}

.dark .circle {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: 5px;
}

.dark .content {
  height: calc(var(--vh, 1vh) * 100);
}

.dark .home {
  width: 400px;
  height: 100%;
  overflow-y: auto;
}

@media only screen and (max-width: 500px) {
  .dark .home {
    width: 250px !important;
  }
}

.dark .home p {
  font-weight: 400;
  line-height: 1.5;
}

canvas > * {
  pointer-events: none !important;
}

.dark .particles-parent {
  position: absolute;
  bottom: -280px;
  left: -180px;
  width: 800px !important;
  height: 800px !important;
  overflow: visible;
  z-index: 9;
  pointer-events: none !important;
}

.dark .particles-parent > * {
  pointer-events: none !important;
}

canvas {
  pointer-events: none !important;
}

.dark .projects .project-name {
  font-size: 55px;
  font-weight: 400;
  margin-bottom: 00px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .projects .project-type {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
}

.dark .projects li {
  text-align: end;
}

.dark .projects a {
  color: white;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .projects a:hover {
  opacity: 0.6;
  cursor: pointer;
}

.dark .content {
  overflow: auto;
}

.dark .projects {
  margin-top: 20px;
  overflow: auto;
}

@media only screen and (max-width: 700px) {
  .dark .nav {
    position: relative;
    background-color: transparent;
    z-index: 99999999 !important;
  }

  .dark .header {
    background-color: transparent;
    position: relative;
    z-index: 999999;
  }

  .dark .nav ul {
    margin-top: 0;
  }
  /* .projects {
    width: 500px !important;
  } */
  /* .nav ul {
    display: flex;
    flex-direction: column-reverse;
    gap: 80px;
    
    
    position: absolute;
    bottom: 50px;
    left: -70px;
  }

  .nav ul li{
    transform: rotate(-90deg);
  }

  .main {
    overflow: visible;
  } */
}

@media screen and (max-width: 1280px) {
  .dark .header h1 {
    font-size: 30px;
  }
  .dark .projects .project-name {
    font-size: 30px;
  }
  .dark .chip {
    font-size: 16px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

.dark .loader {
  background-color: #0c0c0c;
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
  top: -50px;
  left: -0;
  z-index: 9999;
  opacity: 1;
  transition: all 1s ease-in-out;
}

.dark .loader-main {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw !important;
  height: 100vh !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
}

.dark .loader-main .name {
  padding-right: 10px;
  font-weight: 700 !important;
  transition: all 1s ease-in-out;
}

.dark .loader-main .sub {
  font-weight: 400 !important;
}

.dark .opacity {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.dark .p-1 {
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 16px;
  text-align: end !important;
}

.dark .p-2 {
  position: absolute;
  bottom: 80px;
  right: 0;
  font-size: 16px;
}
.dark .p-3 {
  position: absolute;
  bottom: 110px;
  right: 0;
  font-size: 16px;
}

.dark .exp {
  position: absolute;
  top: 0;
  left: 0;
}

.hide {
  transform: translateX(calc(-800px + 1 * var(--pad))) !important;
  transition: transform 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.up {
  transform: translateY(calc(-600px + -1 * var(--pad))) !important;
  transition: transform 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .exp-details {
  width: min(
    calc(100vw - 2 * var(--pad)),
    calc(800px - 2 * var(--pad))
  ) !important;
  height: calc(100vh - 2 * var(--pad)) !important;
  background: #000;
  overflow: hidden;
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  z-index: 2;
  border: 1px solid white;
  transform: translateX(800px);
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .exp-car {
  width: min(
    calc(100vw - 2 * var(--pad)),
    calc(800px - 2 * var(--pad))
  ) !important;
  height: 600px;
  background: #000;
  overflow: hidden;
  position: absolute;
  bottom: var(--pad);
  right: var(--pad);
  transform: translateY(calc(805px + var(--pad)));
  z-index: 2;
  border: 1px solid white;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .exp-particles {
  position: absolute !important;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.dark .exp-details.visible {
  overflow: hidden;
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  z-index: 2;
  border: 1px solid white;
  transform: none;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.dark .exp-car.visible {
  transform: none;
}

.dark .close-icon {
  display: inline;
  color: white !important;
  font-size: 46px;
  padding-right: 20px;
}

.dark .close-icon:hover {
  cursor: pointer;
}

.dark .exp-content {
  width: calc(100% - 1 * var(--pad)) !important;
  height: calc(100% - 4 * var(--pad)) !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 20px;
  border-top: 1px solid white;
  font-weight: 200;
}

.dark .chip {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  text-transform: uppercase;
  padding: 2px 15px !important;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  white-space: nowrap;
}

.dark .chip p {
  margin: 0;
  font-weight: 600;
}

.dark .chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 10px;
  column-gap: 10px;
}

.dark .chip-container-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: end;
  row-gap: 10px;
  column-gap: 10px;
}

.dark .exp-mission {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 32px;
  font-weight: 400;
  margin-right: 30px;
}

.dark .exp-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  width: 100%;
}

.dark .exp-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  padding-left: 20px;
  padding-right: 70px;
}

.dark .exp-row-title {
  opacity: 0.6;
  font-weight: 200;
  font-size: 14px;
}

.dark .car-row-title {
  font-weight: 200;
  font-size: 18px;
}

.dark .exp-row-text {
  font-weight: 200;
}

.dark .exp-tasks {
  overflow: scroll;
  font-size: 28px;
  width: 100%;
}

.dark .skill-group {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
}

.dark .skill-title {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 200;
}

.dark a.project-name {
  color: white;
  font-size: 16px;
}

.dark .external-icon {
  position: relative;
  top: 4px;
  padding-left: 5px;
}

#en-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  align-items: center;
  color: #fff;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-decoration: underline;
  bottom: 0;
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  margin-left: 5px;
  left: 0;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
}

#fr-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  align-items: center;
  color: #fff;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-decoration: underline;
  bottom: calc(var(--pad) + 20px);
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  left: 0;
  margin-left: 5px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
}

/* Define the default state of the square */
.dark .lang-square {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  margin-right: 4px;
  vertical-align: middle;
}

/* Define the active state of the square */
.dark .active-lang-square {
  background-color: #fff;
}

#dark-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  align-items: center;
  color: #fff;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-decoration: underline;
  bottom: 100px;
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  margin-left: 5px;
  left: 0;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
  text-transform: uppercase;
}

#light-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  align-items: center;
  color: #fff;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  bottom: calc(100px + 70px);
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  left: 0;
  margin-left: 5px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
}

.dark .theme-square {
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  margin-right: 4px;
  display: inline-block;
}

.dark .active-theme-square {
  background-color: white;
}

.dark .separation {
  position: absolute;
  left: 0;
  bottom: 90px;
  z-index: 99;
  background-color: white;
  transform: rotate(270deg);
  transform-origin: 0 0;
  width: 1px;
  height: var(--pad);
  opacity: 0;
}

.dark .player {
  align-items: center;
  color: #fff;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 399;
  font-family: "Neue Montreal" !important;
  background-color: #0c0c0c;
  font-size: 12px;
  border-top: 0px solid white;
  bottom: 0;
  left: var(--pad);
  height: var(--pad);
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
  padding: 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.new-nav {
  align-items: center;
  color: #fff;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 399;
  font-family: "Neue Montreal" !important;
  background-color: #0c0c0c;
  font-size: 12px;
  top: 0;
  right: var(--pad);
  min-height: var(--pad);
  max-height: 350px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 1;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.dark .player .controller {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.dark .mantine-fq0g4p {
  height: var(--pad);
  border-bottom: 1px solid #fff;
}

.dark .current {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--pad);
  margin-left: 20px;
}

.dark .current h3 {
  min-width: 215px;
}

.dark .player .item {
  background: none;
  color: white;
  border: none;
  padding: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dark .playlist {
  height: 100%;
  overflow: scroll;
  width: 100%;
  border-bottom: 1px solid #fff;
}

.dark .player button {
  background: none;
  color: white;
  border: none;
  margin-top: 3px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.dark .player .mantine-fq0g4p button {
  background: none;
  color: white;
  border: none;
  height: var(--pad);
  margin-top: 0px;
  font: inherit;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  border-right: 1px solid white;
}

.dark .volume-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  border-bottom: 1px solid white;
  height: var(--pad);
  padding-left: 10px;
}

.dark .mantine-ejm21a {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  width: 5px;
  background-color: #fff;
  border: none;
  color: #fff;
  border-radius: none;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  cursor: pointer;
  border-radius: 1000px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition-duration: 100ms;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
}

.dark .mantine-mu412o::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 0;
  right: calc(0.5rem * -1);
  left: calc(0.5rem * -1);
  background-color: #e9ecef;
  height: 1px;
  margin-top: 0px;
  z-index: 0;
}

.dark .mantine-mu412o {
  position: relative;
  height: 0 !important;
  width: 100%;
  background-color: transparent !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.client-logo {
  width: 30px;
  height: auto;
  border-radius: 100%;
}

.active-nav-btn {
  background-color: white !important;
  color: #0c0c0c !important;
}

.home-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.home-header h1 {
  font-size: 80px;
  font-weight: 400;
}

.home-header h1 em {
  font-style: normal;
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 5px;
}

.home-header p {
  font-size: 20px;
  font-weight: 400;
  margin-top: 0;
}

.home-header div {
  font-size: 20px;
  font-weight: 400;
}

.start-icon {
  margin-top: 30px;
  border: 1px solid white;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  cursor: pointer;
}

.start-icon:hover {
  background-color: black;
}

.project-index {
  font-size: 24px !important;
}

.client-logo-pi {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #b6b6b6;
}

.project-desc {
  font-weight: 400;
  padding-left: 20px;
  width: 200px;
  color: #b6b6b6;
}

.project-item:hover {
  backdrop-filter: brightness(5);
}

.open-icon {
  color: #b6b6b6;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.open-icon-hovered {
  border: 1px solid #b6b6b6;
}

.footname {
  position: absolute;
  bottom: 10px;
  right: 50px;
  color: #b6b6b6;
}

.gallery-title {
  font-size: 32px;
}

.carousel-item {
  height: 100%;
  width: auto;
}

.car-carousel {
  height: 100%;
}

.mantine-Carousel-control {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  color: #fff;
  padding: 2px;
}

.mantine-Carousel-indicator {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  border-radius: 0;
}

.mantine-Carousel-indicator[data-active="true"] {
  background-color: rgba(0, 0, 0, 1) !important;
}

.exp-carou {
  position: relative;
}

.car-expand-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0.75rem;
  margin-top: 0.75rem;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  border: 1px solid #fff;
  color: #fff;
  padding: 2px;
  z-index: 999999999999;
}

.overlay-loader {
  z-index: 99999999999999999999999999;
}