:root {
  --pad: max(20px, 4vmin);
  --color: #68cc59;
  --text-color: #0d0d0d;
}

.light h1 {
  margin-top: 10px;
  margin-bottom: 0;
}

.light ul {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
}

.light body {
  font-family: "Neue Montreal" !important;
  font-weight: 600;
  overflow: hidden;
  width: 100dvw;
  height: 10dvh;
  background-color: #e6e6e6;
  font-size: 14px;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

.App {
  width: 100vw;
  z-index: 0;
  height: 100vh;
}

.light .corner1 {
  width: 0;
  height: 0;
  border-top: 250px solid var(--color);
  border-right: 250px solid transparent;
  z-index: 1;
}

.light .corner2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 250px solid var(--color);
  border-left: 250px solid transparent;
  z-index: 1;
}

.light .main {
  width: calc(100vw - 2 * var(--pad)) !important;
  height: calc(100vh - 2 * var(--pad)) !important;
  overflow: hidden;
  position: absolute;
  top: var(--pad);
  left: var(--pad);
  z-index: 2;
  border: 1px solid black !important;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .header {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.light .header h1 {
  font-size: 55px;
}

.light .header p {
  font-size: 14px;
  font-weight: 400;
  margin-top: -5px;
}

.light .nav ul {
  display: flex;
  flex-direction: column;
}

.light .nav button {
  background: transparent;
  border: none;
  color: #0d0d0d;
  padding-left: 0px;
  padding-top: -10px;
  height: 6px;
  font-weight: 600;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .nav button:hover {
  opacity: 0.4;
}

.light .circle {
  width: 10px;
  height: 10px;
  background-color: #0d0d0d;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: 5px;
}

.light .content {
  position: absolute;
  bottom: 10px;
  right: 30px;
  padding-left: 30px;
  height: calc(var(--vh, 1vh) * 100);
}

.light .home {
  width: 400px;
  height: 100%;
  overflow-y: auto;
}

@media only screen and (max-width: 500px) {
  .light .home {
    width: 250px !important;
  }
}

.light .home p {
  font-weight: 400;
  line-height: 1.5;
}

canvas > * {
  pointer-events: none !important;
}

.light .particles-parent {
  position: absolute;
  bottom: -280px;
  left: -180px;
  width: 800px !important;
  height: 800px !important;
  overflow: visible;
  z-index: 9;
  pointer-events: none !important;
}

.light .particles-parent > * {
  pointer-events: none !important;
}

canvas {
  pointer-events: none !important;
}

.light .projects .project-name {
  font-size: 60px;
  font-weight: 200;
  display: inline;
  margin-left: 20px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .projects .project-type {
  display: inline;
  font-weight: 700;
}

.light .projects li {
  text-align: end;
}

.light .projects a {
  color: #0d0d0d;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .projects a:hover {
  opacity: 0.4;
  cursor: pointer;
}

.light .projects .project-name:hover {
  margin-left: 40px;
}

.light .content {
  overflow: auto;
}

.light .projects {
  margin-top: 280px;
  overflow: auto;
}

@media only screen and (max-width: 700px) {
  .light .nav {
    position: relative;
    background-color: transparent;
    z-index: 99999999 !important;
  }

  .light .header {
    background-color: transparent;
    position: relative;
    z-index: 999999;
  }

  .light .nav ul {
    margin-top: 0;
  }
  /* .projects {
      width: 500px !important;
    } */
  /* .nav ul {
      display: flex;
      flex-direction: column-reverse;
      gap: 80px;
      
      
      position: absolute;
      bottom: 50px;
      left: -70px;
    }
  
    .nav ul li{
      transform: rotate(-90deg);
    }
  
    .main {
      overflow: visible;
    } */
}

@media screen and (max-width: 1280px) {
  .light .header h1 {
    font-size: 30px;
  }
  .light .projects .project-name {
    font-size: 30px;
  }
  .light .chip {
    font-size: 16px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

.light .loader {
  background-color: #e6e6e6 !important;
  color: #0c0c0c !important;
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
  top: -50px;
  left: -0;
  z-index: 9999;
  opacity: 1;
  transition: all 1s ease-in-out;
}

.light .loader-main {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw !important;
  height: 100vh !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
}

.light .loader-main .name {
  padding-right: 10px;
  font-weight: 700 !important;
  transition: all 1s ease-in-out;
}

.light .loader-main .sub {
  font-weight: 400 !important;
}

.light .opacity {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.light .p-1 {
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 16px;
  text-align: end !important;
}

.light .p-2 {
  position: absolute;
  bottom: 80px;
  right: 0;
  font-size: 16px;
}
.light .p-3 {
  position: absolute;
  bottom: 110px;
  right: 0;
  font-size: 16px;
}

.light .exp {
  position: absolute;
  top: 0;
  left: 0;
}

.light .hide {
  transform: translateX(-800px);
  transition: transform 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .exp-details {
  width: min(
    calc(100vw - 2 * var(--pad)),
    calc(800px - 2 * var(--pad))
  ) !important;
  height: calc(100vh - 2 * var(--pad)) !important;
  background: #e6e6e6;
  overflow: hidden;
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  z-index: 2;
  border: 1px solid #0d0d0d;
  transform: translateX(800px);
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .exp-particles {
  position: absolute !important;
  z-index: -1;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.light .exp-details.visible {
  background: #e6e6e6;
  overflow: hidden;
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  z-index: 2;
  border: 1px solid #0d0d0d;
  transform: none;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
}

.light .close-icon {
  display: inline;
  color: #0d0d0d !important;
  font-size: 46px;
  padding: 20px;
}

.light .close-icon:hover {
  cursor: pointer;
}

.light .exp-content {
  width: calc(100% - 1 * var(--pad)) !important;
  height: calc(100% - 4 * var(--pad)) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border-top: 1px solid #0d0d0d;
  font-weight: 200;
}

.light .chip {
  background-color: #0d0d0d;
  color: #e6e6e6;
  text-transform: uppercase;
  padding: 2px 15px !important;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
}

.light .chip p {
  margin: 0;
  font-weight: 600;
}

.light .chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 10px;
  column-gap: 10px;
}

.light .chip-container-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 10px;
  column-gap: 10px;
}

.light .exp-mission {
  font-size: 32px;
  font-weight: 400;
}

.light .exp-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}

.light .exp-row-title {
  opacity: 0.6;
  font-weight: 200;
  font-size: 14px;
}

.light .exp-row-text {
  font-weight: 200;
}

.light .exp-tasks {
  overflow: scroll;
}

.light .skill-group {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
}

.light .skill-title {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 200;
}

.light a.project-name {
  color: #0d0d0d;
  font-size: 16px;
}

.light .external-icon {
  position: relative;
  top: 4px;
  padding-left: 5px;
}

.light #en-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  align-items: center;
  color: #0d0d0d;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-decoration: underline;
  bottom: 0;
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  margin-left: 5px;
  left: 0;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
}

.light #fr-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  align-items: center;
  color: #0d0d0d;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-decoration: underline;
  bottom: calc(var(--pad) + 20px);
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  left: 0;
  margin-left: 5px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
}

/* Define the default state of the square */
.light .lang-square {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #0d0d0d;
  margin-right: 4px;
  vertical-align: middle;
}

/* Define the active state of the square */
.light .active-lang-square {
  background-color: #0d0d0d;
}

.light #dark-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  align-items: center;
  color: #0d0d0d;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-decoration: underline;
  bottom: 100px;
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  margin-left: 5px;
  left: 0;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
  text-transform: uppercase;
}

.light #light-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  align-items: center;
  color: #0d0d0d;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  font-family: "Neue Montreal" !important;
  background-color: transparent;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  bottom: calc(100px + 70px);
  transform: rotate(270deg);
  transform-origin: 0 0;
  height: var(--pad);
  left: 0;
  margin-left: 5px;
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 0;
}

.light .theme-square {
  width: 8px;
  height: 8px;
  border: 1px solid #0d0d0d;
  margin-right: 4px;
  display: inline-block;
}

.light .active-theme-square {
  background-color: #0d0d0d;
}

.light .separation {
  position: absolute;
  left: 0;
  bottom: 90px;
  z-index: 99;
  background-color: black;
  transform: rotate(270deg);
  transform-origin: 0 0;
  width: 1px;
  height: var(--pad);
}

.light .player {
  align-items: center;
  color: #0c0c0c;
  border: none;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  z-index: 99999;
  font-family: "Neue Montreal" !important;
  background-color: #e6e6e6;
  font-size: 12px;
  min-height: var(--pad);
  max-height: 350px;
  border-left: 1px solid #0c0c0c;
  border-bottom: 1px solid #0c0c0c;
  top: 0;
  right: var(--pad);
  transition: all 0.4s cubic-bezier(0.1, 0.4, 0.2, 1);
  opacity: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.light .player .controller {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.light .mantine-fq0g4p {
  height: var(--pad);
  border-bottom: 1px solid #0c0c0c;
}

.light .current {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.light .current h3 {
  min-width: 215px;
  margin-top: 3px;
  margin-bottom: 0;
}

.light .player .item {
  background: none;
  color: #0c0c0c;
  border: none;
  padding: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.light .playlist {
  height: 100%;
  overflow: scroll;
  width: 100%;
  border-bottom: 1px solid #0c0c0c;
}

.light .player button {
  background: none;
  color: #0c0c0c;
  border: none;
  margin-top: 3px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.light .volume-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #0c0c0c;
  height: var(--pad);
  padding-left: 10px;
}

.light .player .mantine-fq0g4p button {
  background: none;
  color: #0c0c0c;
  border: none;
  height: var(--pad);
  margin-top: 0px;
  font: inherit;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
  border-right: 1px solid #0c0c0c;
}

.light .mantine-1h2upig {
  background-color: #0c0c0c;
} 

.light .mantine-1mwedzw {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: calc(0.5rem * 2);
  width: calc(0.5rem * 2);
  background-color: #0c0c0c;
  border: 0.25rem solid #228be6;
  color: #0c0c0c;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  cursor: pointer;
  border-radius: 1000px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition-duration: 100ms;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  height: 20px;
  width: 5px;
  border-radius: 0px;
  border-width: 0px;
}

.light .mantine-ejm21a {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  width: 5px;
  background-color: #0c0c0c;
  border: none;
  color: #0c0c0c;
  border-radius: none;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  cursor: pointer;
  border-radius: 1000px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition-duration: 100ms;
  transition-property: box-shadow, transform;
  transition-timing-function: ease;
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
}

.light .mantine-mu412o::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 0;
  right: calc(0.5rem * -1);
  left: calc(0.5rem * -1);
  background-color: #0c0c0c;
  height: 1px;
  margin-top: 0px;
  z-index: 0;
}

.light .mantine-mu412o {
  position: relative;
  height: 0 !important;
  width: 100%;
  background-color: transparent !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
