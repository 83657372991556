@font-face {
  font-family: "Neue Montreal";
  src: url("./fonts/PPNeueMontreal-Thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "MyFont";
  src: url("./fonts/PPNeueMontreal-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MyFont";
  src: url("./fonts/PPNeueMontreal-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MyFont";
  src: url("./fonts/PPNeueMontreal-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
